<template>
  <div @click="handleFocusOut($event)" class="admin-app-container">
    <Loading v-if="loading" />
    <Header />
    <div class="pt-1 pa-sm-6 app-container">
      <h2 class="pl-2 pr-2 pa-sm-2 text-center">Cadastro de Usuários Administradores</h2>
      <Grid
        class="grid-component"
        ref="gridComponent"
        :fields="fields"
        :list="list"
        :filters="filters"
        :defaultInsertObject="defaultInsertObject"
        gridType="responsive"
        filterType="search"
        gridOverflow="vertical"
        :gridResizable="false"
        @listarItens="listarItens($event)"
        @selecionarItem="selecionarItem($event)"
        @exportarExcel="exportarExcel($event)"
        @salvarItem="salvarItem()"
        @botaoClick="botaoClick($event)"
        :minimizedItemList="[]"
        gridTableId="grid-admin"
        :hideInsert="false"
      />
      <Paginacao
        id="paginacao"
        :totalPaginas="totalPages"
        :paginaAtual="filters.pagina"
        :totalItens="totalItems"
        @alterarItensPorPagina="alterarItensPorPagina($event)"
        @alterarPagina="alterarPagina($event)"
      />
      <ModalSucesso
        :mensagem="mensagemAlerta"
        :alertaSucesso="alertaSucesso"
        :titulo="mensagemTitulo"
        @ocultarSucesso="ocultarSucesso"
      />
      <MensagemErro
        :mensagemAlerta="mensagemAlerta"
        :alertaErro="alertaErro"
        @ocultarErro="alertaErro = false"
        :login="false"
        :timeout="3000"
      />
      <v-row justify="center" v-if="item">
        <v-dialog
          v-model="modalAdmin"
          max-width="600px"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Cadastro de Usuário Administrador</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeModalAdmin">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form ref="formAdmin" class="pt-4">
                <v-row class="pt-2 pb-2">
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refNome"
                      label="Nome"
                      dense
                      v-model="item.nome"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo nome é obrigatório']"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refTelefone"
                      label="Telefone"
                      dense
                      v-model="item.telefone"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo telefone é obrigatório']"
                      v-mask="['+## #####-######']"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-text-field
                      ref="refEmail"
                      label="E-mail"
                      dense
                      v-model="item.email"
                      clearable
                      outlined
                      :rules="[(v) => !!v || 'O campo e-mail é obrigatório']"
                      :error-messages="item.emailDuplicado"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" class="pa-0 pa-sm-1">
                    <v-select
                      v-model="item.ativo"
                      item-text="text"
                      item-value="value"
                      :items="[
                        {value: true, text: 'Ativo'},
                        {value: false, text: 'Inativo'}
                      ]"
                      attach
                      label="Status"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                right
                class="text-caption"
                @click="closeModalAdmin"
              >
                <v-icon class="text-caption">mdi-close</v-icon>Cancelar
              </v-btn>
              <v-btn color="blue" text class="text-caption" @click="salvarItem">
                <v-icon class="text-caption">mdi-content-save-outline</v-icon>
                {{ item.id ? "Salvar" : "Inserir" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="modalSenha" max-width="500px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Alterar Senha</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="limparAlterarSenha">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form ref="formularioAlteracao" class="pt-4">
                  <v-row class="pt-2 pb-0">
                    <v-col cols="12" class="pa-0 pa-sm-1">
                      <v-text-field
                        id="password"
                        label="Nova senha"
                        prepend-icon="lock"
                        :type="showNovaSenha ? 'text' : 'password'"
                        v-on:keyup.enter="alterarSenha"
                        v-model="novaSenha"
                        :rules="[v => !!v || 'Digite a senha']"
                        :append-icon="showNovaSenha ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showNovaSenha = !showNovaSenha"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" class="pa-0 pa-sm-1">
                      <v-text-field
                        id="password2"
                        label="Confirme a nova senha"
                        prepend-icon="lock"
                        :type="showConfirmarSenha ? 'text' : 'password'"
                        v-on:keyup.enter="alterarSenha"
                        v-model="novaSenhaConfirmacao"
                        :rules="[v => !!v || 'Digite a senha']"
                        :append-icon="showConfirmarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showConfirmarSenha = !showConfirmarSenha"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
              </v-form>
            </v-card-text>
            <div class="pr-8 pl-8 text-center">
              <v-alert :value="alertConfirmacao" color="red" transition="scale-transition" text>
                <v-icon color="red" left>mdi-alert</v-icon>
                {{mensagemAlteracao}}
              </v-alert>
            </div>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text right class="text-caption" @click="limparAlterarSenha">
                <v-icon class="text-caption">mdi-close</v-icon>Cancelar
              </v-btn>
              <v-btn color="blue" text class="text-caption" @click="alterarSenha">
                <v-icon class="text-caption">mdi-check</v-icon>Alterar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import Paginacao from "../../components/paginacao.vue";
import Grid from "../../components/grid.vue";
import UsuarioService from "../../service/usuario-service.js";
import ExcelService from "../../service/excel-service.js";
import ModalSucesso from "../../components/modal-sucesso.vue";
import MensagemErro from "../../components/mensagem-erro.vue";
import { mask } from "vue-the-mask";

const usuarioService = new UsuarioService();
const excelService = new ExcelService();

export default {
  directives: {
    mask,
  },
  components: {
    Loading,
    Header,
    Paginacao,
    Grid,
    ModalSucesso,
    MensagemErro,
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      loading: false,
      filters: usuarioService.listarFiltros(),
      modalAdmin: false,
      item: null,
      totalPages: 1,
      totalItems: 1,
      fields: [],
      list: [],
      defaultInsertObject: {
        id: null,
        nome: null,
        telefone: null,
        email: null,
        admin: true,
        ativo: true
      },
      windowWidth: window.innerWidth,
      novaSenha: "",
      novaSenhaConfirmacao: "",
      showNovaSenha: false,
      showConfirmarSenha: false,
      modalSenha: false,
      mensagemAlteracao: "",
      alertConfirmacao: false,
    };
  },
  methods: {
    handleFocusOut(event) {
      this.$refs.gridComponent?.handleFocusOut(event);
    },
    async exportarExcel(filtros) {
      this.loading = true;

      let result = await usuarioService.exportarUsuariosExcel(filtros);
      if (result?.statusCode === 200) {
        excelService.downloadFile(result?.data, "Usuarios.xlsx");
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    selecionarItem(item) {
      this.item = JSON.parse(JSON.stringify(item));
      this.modalAdmin = true;
    },
    async salvarItem() {
      this.item.descricaoDuplicada = null;

      if (this.$refs.formAdmin.validate()) {
        this.loading = true;
        let result = this.item.id
          ? await usuarioService.atualizar(this.item)
          : await usuarioService.cadastrar(this.item);
        if (result?.statusCode === 200) {
          if (this.item.id) {
            this.mensagemAlerta =
              "O usuário '" + this.item.nome + "' foi editado com sucesso";
            this.mensagemTitulo = "Edição de usuário";
          } else {
            this.mensagemAlerta =
              "O usuário '" + this.item.nome + "' foi inserido com sucesso";
            this.mensagemTitulo = "Inserção de usuário";
          }
          this.alertaSucesso = true;
          this.closeModalAdmin();
          this.$refs.formAdmin.resetValidation();
        } else if (result?.statusCode === 409) {
          this.item.emailDuplicado = result?.data.mensagem;
          this.$refs.refEmail.focus();
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      }
    },
    alterarPagina(pagina) {
      this.filters.pagina = pagina;
      this.listarItens();
    },
    alterarItensPorPagina(itens) {
      this.filters.itensPagina = itens;
      this.filters.pagina = 1;
      this.listarItens();
    },
    async listarItens() {
      this.loading = true;
      let result = await usuarioService.listarUsuarios(this.filters);
      if (result?.statusCode === 200) {
        this.list = result?.data.lista;
        this.totalPages = result?.data.paginas;
        this.totalItems = result?.data.totalItens;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalAdmin() {
      this.loading = false;
      this.modalAdmin = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    ocultarSucesso() {
      this.alertaSucesso = false;
      this.loading = false;
      this.listarItens();
    },
    botaoClick(item) {
      this.item = item;
      this.modalSenha = true;
    },
    limparAlterarSenha() {
      this.$refs.formularioAlteracao.resetValidation();
      this.mensagemAlteracao = "";
      this.alertConfirmacao = false;
      this.novaSenha = "";
      this.novaSenhaConfirmacao = "";
      this.showNovaSenha = false;
      this.showConfirmarSenha = false;
      this.modalSenha = false;
      this.item = null;
      const appContainer = document.querySelectorAll(".app-container")[0];
      appContainer.click();
    },
    exibirAlterarSenha(item) {
      this.modalSenha = true;
      this.item = item;
      this.item.primeiroAcesso = false;
      this.item.grupos = item.grupos;
    },
    async alterarSenha() {
      if (this.$refs.formularioAlteracao.validate()) {
        if (this.novaSenha != this.novaSenhaConfirmacao) {
          this.alertConfirmacao = true;
          this.mensagemAlteracao = "As senhas informadas não coincidem";
          setTimeout(() => {
            this.alertConfirmacao = false;
          }, 2000);
        } else {
          this.loading = true;
          let result = await usuarioService.alterarSenhaUsuario(this.item.id, this.novaSenha);
          if (result?.statusCode === 200) {
            this.mensagemAlerta =
              "A senha do usuário '" + this.item.nome + "' foi alterada com sucesso";
            this.mensagemTitulo = "Alteração de senha";
            this.alertaSucesso = true;
            this.limparAlterarSenha();
            this.loading = false;
          } else {
            this.mensagemAlerta = result?.data.mensagem;
            this.alertaErro = true;
            this.loading = false;
          }
        }
      }
    },
  },
  created: async function () {
    this.fields = await usuarioService.listarCampos();
    this.listarItens();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.grid-component {
  max-height: calc(100vh - 284px) !important;
}
.admin-app-container {
  height: 100vh;
}
@media (max-width: 600px) {
  .grid-component {
    max-height: calc(100vh - 244px) !important;
  }
}
</style>